const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.Map({
    footerIsVisible: true,
    headerIsVisible: true,
    isMobile: false,
    isTablet: false,
    isDesktop: true, // @hardcoded: change me to false when mobile and tablet support is re-enabled
    device: 'desktop' // @hardcoded: change me to '' when mobile and tablet support is re-enabled
});

function screenReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SCREEN_IS_MOBILE:
            return state.merge({
                device: 'mobile',
                isMobile: true,
                isTablet: false,
                isDesktop: false
            })
        case actionTypes.SCREEN_IS_TABLET:
            return state.merge({
                device: 'tablet',
                isMobile: false,
                isTablet: true,
                isDesktop: false
            })
        case actionTypes.SCREEN_IS_DESKTOP:
            return state.merge({
                device: 'desktop',
                isMobile: false,
                isTablet: false,
                isDesktop: true
            })
        case actionTypes.SCREEN_SHOW_APP_HEADER:
            return state.merge({
                headerIsVisible: true
            });
        case actionTypes.SCREEN_HIDE_APP_HEADER:
            return state.merge({
                headerIsVisible: false
            });
        case actionTypes.SCREEN_SHOW_APP_FOOTER:
            return state.merge({
                footerIsVisible: true
            });
        case actionTypes.SCREEN_HIDE_APP_FOOTER:
            return state.merge({
                footerIsVisible: false
            });
        default:
            return state
    }
}

module.exports = screenReducer;
