// @vendor
const Immutable = require('immutable');

// @constants
const { actionTypes } = require('../migrationUsersBranchView.constants');
const globalActionTypes = require('constants/actionTypes');

const setInitialState = () =>
  Immutable.fromJS({
    state: '',
    fetching: false,
    fetched: false,
    gpData: undefined,
  });

function migration(state = setInitialState(), action = {}) {
  switch (action.type) {
    // Get migration data actions
    case actionTypes.FETCH_GET_MIGRATION_DATA_IN_PROGRESS:
      return state.merge({
        fetching: true,
      });
    case actionTypes.FETCH_GET_MIGRATION_DATA_FAILURE:
      return state.mergeDeep({
        fetching: false,
        error: action.payload.error,
      });
    case actionTypes.FETCH_GET_MIGRATION_DATA_SUCCESS:
      return state.mergeDeep({
        fetched: true,
        fetching: false,
        status: action.payload.state,
        error: undefined,
      });
    case globalActionTypes.GLOBAL_POSITION_REQUEST_SUCCESS:
      return state.mergeDeep({
        gpData: action.payload,
      });
    default:
      return state;
  }
}

module.exports = migration;
