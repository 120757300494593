// @ vendors
const Immutable = require('immutable');
const trim = require('lodash/string/trim');
const get = require('lodash/object/get');
const find = require('lodash/collection/find');
const moment = require('moment');
// @ constants
const actionTypes = require('constants/actionTypes');
const { FIRST_STEP, SECOND_STEP, BROKER_FUND_NEW_CONTRACT_OPTION } = require('constants/index');
// @ reducers
const investmentFundsStep1 = require('./investmentFunds/investmentFundsStep1');
const investmentFundsStep2 = require('./investmentFunds/investmentFundsStep2');
const investmentFundsPeriodicalContributionStep = require('./investmentFunds/investmentFundsPeriodicalContributionStep');
// @ utilities
const { generateAccountId } = require('utilities/APIParsingHelper');
// @core
const { getStore } = require('core/storeHelper');

function setInitialState(funds = []) {
    return Immutable.Map().merge({
        steps: [
            investmentFundsStep1(undefined, { type: null }),
            investmentFundsPeriodicalContributionStep(undefined, { type: null }),
            investmentFundsStep2(undefined, { type: null }),
        ],
        list: Immutable.List(funds),
        investmentFunds: Immutable.fromJS({}),
        errorReceived: '',
        isFetching: false,
        isFetchingPre: false,
        isFetchedPre: false,
        inProgress: false,
        willLeave: false,
        visibleStep: 1,
        submitSuccessful: false,
        notFound: false,
        holding: {
            numberOfHoldings: 0,
            currency: '',
            amount: 0
        },
        fetchingDataExt: false,
        fetchDataExt: [],
        fetchDataExtSuccess: false,
        fetchDataExtError: false,
        enableEconomicReport: true,
        skipContribution: false,
    });
}

function pad(num, size) {
    return (Math.pow(10, size) + ~~num).toString().substring(1);
}

function isAdditionalSubscription(immState) {
    return immState.getIn(['steps', FIRST_STEP, 'isSubscription']);
}

function getMinimumAmount(immState) {
    if (isAdditionalSubscription(immState)) {
        const minimumAmountForAdditionalSubscription = immState.getIn([
            'steps',
            FIRST_STEP,
            'investmentFunds',
            'customMinimumPurchaseAmount',
            'additionalSubscriptionAmount'
        ]);
        return minimumAmountForAdditionalSubscription;
    }

    const minimumAmountForInitialSubscription = immState.getIn([
        'steps',
        FIRST_STEP,
        'investmentFunds',
        'customMinimumPurchaseAmount',
        'amount'
    ]);
    return minimumAmountForInitialSubscription;
}

function getHiredFundByContractAndProduct(hiredFunds, contract, product) {
    const cleanContract = trim(contract);
    const cleanProduct = trim(product);

    return find(hiredFunds, (hiredFund) => {
        const fundContract = trim(get(hiredFund, 'contractNumber'));
        const fundProduct = trim(get(hiredFund, 'product'));

        return fundContract === cleanContract && fundProduct === cleanProduct;
    });
}

function getControlDigitFromAdditionalSubscription(immState, hiredFunds) {
    const subscribedFundContract = immState.getIn(['contractFund', 'contractNumber']);
    const subscribedFundProduct = immState.getIn(['contractFund', 'product']);
    const subscribedFund = getHiredFundByContractAndProduct(
        hiredFunds,
        subscribedFundContract,
        subscribedFundProduct
    );

    return trim(get(subscribedFund, 'controlDigit'));
}

const parseInterveresFundContract = data => {
    const result = data.map(intervener => {
        return {
            name: trim(intervener.nomInterviniente),
            type: trim(intervener.tipoIntervencion),
        }
    })

    return result
}

function successFundAdditionalSubscription(immState, hiredFunds) {
    const subscribedFundControlDigit = getControlDigitFromAdditionalSubscription(
        immState,
        hiredFunds
    );

    return immState.mergeDeep({
        contractFund: {
            controlDigit: subscribedFundControlDigit
        },
        submitSuccessful: true,
        inProgress: false,
        isFetching: false
    });
}


function getMinimumAmountCurrency(immState) {
    return immState.getIn([
        'steps',
        FIRST_STEP,
        'investmentFunds',
        'customMinimumPurchaseAmount',
        'currency'
    ]);
}

function updateMinimumAmounts(immState) {
    const minimumAmount = getMinimumAmount(immState);
    const minimumAmountCurrency = getMinimumAmountCurrency(immState);

    return immState.mergeDeepIn(['steps', FIRST_STEP], {
        customMinimumPurchaseAmount: {
            amount: minimumAmount,
            currency: minimumAmountCurrency
        }
    });
}

function parseInterveners(interveners) {
    return interveners.map(intervener => {
        return {
            name: trim(intervener.nombre),
            type: trim(intervener.desIntervencion),
            interventionType: trim(intervener.tipoIntervencion),
            interventionForm: trim(intervener.formaIntervencion),
            documentNumber: trim(intervener.numDocumento),
            documentType: trim(intervener.tipoDocumento),
            personNumber: trim(intervener.numPersona),
            personType: trim(intervener.tipPers)
        };
    });
}

function filterAvailables(currentInterveners = [], originalInterveners = []) {
    return originalInterveners.filter(intervener => {
        return !currentInterveners.find(originalIntervener => {
            return intervener.get('name') === originalIntervener.get('name');
        });
    });
}

function updateStep1(steps, action) {
    return steps.update(0, step => investmentFundsStep1(step, action));
}

function updateStep2(steps, action) {
    return steps.update(1, step => investmentFundsPeriodicalContributionStep(step, action));
}

function updateStep3(steps, action) {
    return steps.update(2, step => investmentFundsStep2(step, action));
}

const documents = (state = setInitialState(), action) => {
    let visibleStep;
    let partialSteps;
    let newState;
    switch (action.type) {
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_RESTORE_DEFAULT_INTERVENERS:
            return state.mergeIn(['steps', 0], {
                interveners: action.payload.originalInterveners,
                availableInterveners: Immutable.List()
            });
        case actionTypes.BROKER_STOCKS_CONTRACT_ADD_INTERVENER: {
            let availableInterveners = state.getIn(['steps', 0, 'availableInterveners']);
            const intervener = availableInterveners.get(availableInterveners.size - 1);
            const interveners = state.getIn(['steps', 0, 'interveners']);
            const nextInterveners = interveners.push(intervener);
            availableInterveners = availableInterveners.pop();

            return state.mergeIn(['steps', 0], {
                interveners: nextInterveners,
                availableInterveners
            });
        }
        case actionTypes.FETCH_BROKER_INVESTMENT_FUNDS_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.FETCH_BROKER_INVESTMENT_FUNDS_PRE_REQUEST:
            return state.merge({
                isFetchingPre: true
            });
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_SET_IS_INVALID_SCHEMA:
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_SET_IS_VALID_SCHEMA:
        case actionTypes.BROKER_CLEAR_ALL_FUNDS_CONTRACT_INTERVENERS:
        case actionTypes.FETCH_BROKER_GET_ALL_FUND_CONTRACT_INTERVENERS_SUCCESS:
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_SET_AMOUNT:
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_SET_ACCOUNT_NUMBER:
            partialSteps = updateStep1(state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_IS_SUBSCRIPTION:
            partialSteps = updateStep1(state.get('steps'), action);
            return updateMinimumAmounts(
                state.merge({ steps: partialSteps })
            );
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_IS_NOT_SUBSCRIPTION:
            partialSteps = updateStep1(state.get('steps'), action);
            return updateMinimumAmounts(
                state.delete('subscriptionFund').merge({ steps: partialSteps })
            );
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_CREATE_NEW_CONTRACT:
            partialSteps = updateStep1(state.get('steps'), action);
            return state.merge({steps: partialSteps})
                .merge({
                    contractFund: {
                        contractNumber: BROKER_FUND_NEW_CONTRACT_OPTION,
                        product: null,
                        controlDigit: null
                    }
                });
        case actionTypes.FETCH_BROKER_GET_ACCOUNTS_HIRE_SUCCESS:
            const minimumPurchaseAmount = {
                amount:  action.payload.data.minimumPurchase.amount || 0,
                currency: action.payload.data.minimumPurchase.currency,
                additionalSubscriptionAmount: action.payload.data.minimumPurchase.additionalSubscriptionAmount || 0
            }
            const ibanList = action.payload.data.listaCuentasCorrientes.dato.map(account => {
                const iban = {
                    country: trim(account.contratoUnificado.pais),
                    controlDigit: trim(account.contratoUnificado.digitodecontrol),
                    codbban: trim(account.contratoUnificado.codbban)
                };
                return generateAccountId(iban);
            });
            newState = state.mergeIn(['steps', FIRST_STEP], {
                fundAccounts: ibanList
            }).mergeIn(['steps', FIRST_STEP, 'investmentFunds', 'customMinimumPurchaseAmount'], minimumPurchaseAmount);
            return updateMinimumAmounts(
                newState.merge({
                    isFetching: false
                })
            );
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_RESET:
            return setInitialState(state.get('list'));
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_CHANGE_WILL_CANCEL:
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_CHANGE_WILL_LEAVE:
            return state.merge({
                willLeave: true
            });
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_CHANGE_SET_FIELD:
            return state.merge({
                inProgress: true
            });
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_CHANGE_SUCCESS:
            return successFundAdditionalSubscription(
                state,
                get(action, 'payload.hiredFunds')
            );
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_ADD_INTERVENER: {
            let availableInterveners = state.getIn(['steps', 0, 'availableInterveners']);
            if (availableInterveners) {
                const intervener = availableInterveners.get(availableInterveners.size - 1);
                const interveners = state.getIn(['steps', 0, 'interveners']);
                const nextInterveners = interveners.push(intervener);
                availableInterveners = availableInterveners.pop();

                return state.mergeIn(['steps', 0], {
                    interveners: nextInterveners,
                    availableInterveners
                });
            }
        }
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_REMOVE_INTERVENER: {
            const interveners = state.getIn(['steps', 0, 'interveners']);
            let nextInterveners = interveners.splice(action.payload.index, 1);
            const availableInterveners = filterAvailables(
                nextInterveners,
                action.payload.originalInterveners
            );
            if (nextInterveners.size === 1) {
                nextInterveners = nextInterveners
                    .setIn([0, 'type'], 'TITULAR')
                    .setIn([0, 'interventionType'], '01');
            }

            return state.mergeIn(['steps', 0], {
                interveners: nextInterveners,
                availableInterveners
            });
        }
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_SET_ACCOUNT_INTERVENER_NAME: {
            let interveners = state.getIn(['steps', 0, 'interveners']);
            const nextInterveners = interveners.setIn(
                [action.payload.index, 'name'],
                action.payload.name
            );
            const availableInterveners = filterAvailables(
                nextInterveners,
                action.payload.originalInterveners
            );

            return state.mergeIn(['steps', 0], {
                interveners: nextInterveners,
                availableInterveners
            });
        }
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_SET_ACCOUNT_INTERVENER_TYPE:
            return state.setIn(
                ['steps', 0, 'interveners', action.payload.index, 'interventionType'],
                action.payload.type
            );
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_CHANGE_AUTHORIZATION:
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_CHANGE_READCHECKED:
            partialSteps = updateStep3(state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.FETCH_BROKER_PRE_HIRE_SUCCESS:
            if (action.payload.featureFlag) {
                newState = state.mergeIn(['steps', 1], {
                    preContract: {
                        fundName: trim(action.payload.data.response.nombreFondo),
                        currency: action.payload.data.response.moneda
                    }
                });
                return newState.merge({
                    isFetchingPre: false,
                    isFetchedPre: true,
                    contractFund: {
                        product: action.payload.data.response.contratoFondo.producto,
                        contractNumber: action.payload.data.response.contratoFondo.numerodecontrato,
                        controlDigit: trim(get(action, 'payload.data.contratoFondoDigitoControl'))
                    },
                });
            } else {
                newState = state.mergeIn(['steps', 1], {
                    preContract: {
                        fundName: trim(action.payload.data.nombreFondo),
                        currency: action.payload.data.moneda
                    }
                });
                return newState.merge({
                    isFetchingPre: false,
                    isFetchedPre: true,
                    contractFund: {
                        product: action.payload.data.contratoFondo.producto,
                        contractNumber: action.payload.data.contratoFondo.numerodecontrato,
                        controlDigit: trim(get(action, 'payload.data.contratoFondoDigitoControl'))
                    }
                });
            }
        case actionTypes.FETCH_BROKER_HIRE_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
                emissionDate: moment(parseInt(get(action, 'payload.data.timestamp', '')))
            });
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_CLEAR_FUND_CONTRACT:
            return state.merge({
                contractFund: {
                    product: null,
                    contractNumber: null,
                    controlDigit: null
                }
            });
        case actionTypes.FETCH_BROKER_ADD_SUBSCRIPTION_DETAILS:
            const { data, account, contract, fundControlDigit } = action.payload;
            const iban = {
                country: trim(data.cuentaDomi.pais),
                controlDigit: trim(data.cuentaDomi.digitodecontrol),
                codbban: trim(data.cuentaDomi.codbban)
            };
            newState = state.mergeIn(['steps', 0], {
                accountNumber: generateAccountId(iban),
                accountAmount: {
                    amount: account.amount,
                    currency: account.currency
                }
            });
            return newState.merge({
                subscriptionFund: {
                    fundName: data.descFondo,
                    account: iban,
                    currency: data.codMomCuenta,
                    liquidDate: data.fechValLiquidativo,
                    liquidDateToShow: data.fechaDeValorLiquid,
                    liquidAmount: {
                        amount: parseFloat(
                            data.importeValorLiquidativo.valorliquidativo
                        ).toFixed(2),
                        currency: data.importeValorLiquidativo.divisa
                    }
                },
                contractFund: {
                    contractNumber: contract.getIn(['contrato', 'numerodecontrato']),
                    product: contract.getIn(['contrato', 'producto']),
                    controlDigit: fundControlDigit
                },
                isFetching: false,
                holding: {
                    numberOfHoldings: contract.get('participaciones'),
                    currency: contract.getIn(['saldo1', 'divisa']),
                    amount: contract.getIn(['saldo1', 'importe'])
                }
            });
        case actionTypes.FETCH_BROKER_GET_INTERVENERS_SUCCESS: {
            const immProfile = getStore().getState().profile;
            const userIntervener = action.payload.data.intervinientes.dato.find(
                (intervener) =>
                    intervener.numPersona === immProfile.getIn(['codigoPersona', 'f'])
            );
            const userIsHolder = !!userIntervener && userIntervener.tipoIntervencion.trim() === "01"
            const intervenersRawList = !!userIntervener && userIsHolder
            ? [
                action.payload.data.intervinientes.dato.find(
                    (intervener) =>
                        intervener.numPersona === immProfile.getIn(['codigoPersona', 'f'])
                ),
                ...action.payload.data.intervinientes.dato.filter(
                    (intervener) =>
                        intervener.numPersona !== immProfile.getIn(['codigoPersona', 'f'])
                )
            ] : action.payload.data.intervinientes.dato;
            const nextInterveners = Immutable.fromJS(
                parseInterveners(intervenersRawList)
            );
            const interveners = {
                interveners: Immutable.List(nextInterveners),
                originalInterveners: Immutable.List(nextInterveners),
                userToBlock: userIsHolder && userIntervener
            };
            newState = state.mergeIn(['steps', 0], interveners);

            return newState.merge({
                isFetching: false
            });
        }
        case actionTypes.FETCH_BROKER_GET_INTERVENERS_CONTRACT_SUCCESS: {
            const interveners = parseInterveresFundContract(action.payload.data.listadoIntervinientes.intervinientes)
            return state.merge({
                isFetching: false,
                interveresFundContract: interveners
            });
        }
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_CLEAR_FETCHED_PRE: {
            return state.merge({
                isFetchedPre: false
            });
        }
        case actionTypes.GLOBAL_POSITION_REQUEST_SUCCESS: {
            return state.merge({
                list: action.payload && action.payload.datosSalidaFondos ? action.payload.datosSalidaFondos.fondos : []
            });
        }
        case actionTypes.INVESTMENT_FUND_DOCUMENT_STORAGE_REQUEST:
            return state.setIn(['steps', SECOND_STEP, 'documentStorage'], true)
        case actionTypes.INVESTMENT_FUND_DOCUMENT_STORAGE_SUCCESS:
            return state.setIn(['steps', SECOND_STEP, 'documentStorage'], false)
        case actionTypes.INVESTMENT_FUND_DOCUMENT_STORAGE_ERROR:
            return state.setIn(['steps', SECOND_STEP, 'documentStorage'], false)
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_CHANGE_CONTRACT:
            return state.merge({
                contractFund: action.payload
            });
        case actionTypes.BROKER_EXTANT_COST_FETCH_FETCHING:
            return state.merge({
                fetchingDataExt: true
            });
        case actionTypes.BROKER_EXTANT_COST_FETCH_SUCCESS:
            const ffContribution= getStore().getState().flagBasedFeatures.get('wealth_funds_periodic_contributions');
            const isSubscription = state.getIn(['steps', FIRST_STEP, 'isSubscription']);
            return state.merge({
                fetchDataExt: ffContribution && !isSubscription
                    ? action.payload : null,
                fetchingDataExt: false
            });
        case actionTypes.BROKER_EXTANT_COST_FETCH_FAILURE:
            return state.merge({
                fetchDataExt: [],
                fetchDataExtError: action.payload,
                fetchingDataExt: false
            });
        case actionTypes.BROKER_HIRE_INVESTMENT_FUND_VALIDATE_DOCUMENTS_REQUEST:
            return state.merge({
                fetchingDocumentsValidate: true
            })
        case actionTypes.BROKER_HIRE_INVESTMENT_FUND_VALIDATE_DOCUMENTS_SUCCESS:
            return state.merge({
                fetchingDocumentsValidate: false,
                enableEconomicReport: action.payload.enableEconomicReport
            })
        case actionTypes.BROKER_HIRE_INVESTMENT_FUND_VALIDATE_DOCUMENTS_FAILURE:
            return state.merge({
                fetchingDocumentsValidate: false
            })
        case actionTypes.BROKER_HIRE_INVESTMENT_FUND_VALIDATE_ECONOMIC_REPORT_REQUEST:
            return state.merge({
                fetchingEconomicReportValidation: true,
            })
        case actionTypes.BROKER_HIRE_INVESTMENT_FUND_VALIDATE_ECONOMIC_REPORT_SUCCESS:
        case actionTypes.BROKER_HIRE_INVESTMENT_FUND_VALIDATE_ECONOMIC_REPORT_FAILURE:
            return state.merge({
                fetchingEconomicReportValidation: false,
            })
        case actionTypes.BROKER_HIRE_INVESTMENT_FUND_SET_INCEPTION_DATE:
            return state.merge({
                inceptionDate: action.payload.inceptionDate
            });
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_SET_CLOSE_PRICE:
            return state.mergeIn(['steps', FIRST_STEP, 'investmentFunds', 'netAssetValue'],
                {
                    dayEndDate: action.payload.response.date,
                    currency: action.payload.response.currency,
                    amount: action.payload.response.value
                });
        case actionTypes.FETCH_BROKER_INVESTMENT_FUNDS_SUCCESS:
            const investmentFund = action.payload.investmentFunds.rows[0];
            const securityDetails = action.payload.securityDetails[0];
            const type = investmentFund.CustomInstitutionSecurityId.substring(0, 3);
            const subtype = investmentFund.CustomInstitutionSecurityId.substring(4, 7);
            const reference = pad(investmentFund.CustomInstitutionSecurityId.substring(8, 9), 7);
            const trailingPerformanceLength = securityDetails.TrailingPerformance.length;
            const trailingPerformanceArray = securityDetails.TrailingPerformance[trailingPerformanceLength - 1].Return;
            const variationPercentObject = trailingPerformanceArray.find(value => value.TimePeriod === "D1");
            const variationPercent = !!variationPercentObject ? variationPercentObject.Value : 0;
            const netAssetObject = securityDetails.TrailingPerformance[0].Return.find(value => value.TimePeriod === "ClosePrice");
            const netAsset = !!netAssetObject ? netAssetObject.Value : 0;
            const variationPoints =
                parseFloat(variationPercent) *
                parseFloat(netAsset) / 100;
            const netAssetValueAmount = Number(netAsset) || 0;

            const currency = securityDetails.Currency.Id;

            newState = state.mergeDeepIn(['steps', 0], {
                customMinimumPurchaseAmount: {
                    currency: currency
                },
                investmentFunds: {
                    manager: trim(get(investmentFund, 'AdministratorCompanyName')),
                    customMinimumPurchaseAmountUnit: investmentFund.CustomMinimumPurchaseAmountUnit,
                    customCategoryId3: investmentFund.CustomCategoryId3,
                    customInstitutionSecurityId: investmentFund.CustomInstitutionSecurityId,
                    name: investmentFund.Name,
                    isin: investmentFund.ISIN,
                    secId: investmentFund.SecId,
                    rentability: investmentFund.ReturnM0 || 0,
                    netAssetValue: {
                        dayEndDate: securityDetails.TrailingPerformance[0].Date,
                        currency: securityDetails.Currency.Id,
                        amount: netAssetValueAmount
                    },
                    variationPoints: variationPoints,
                    variationPercent: variationPercent,
                    type,
                    subtype,
                    reference
                },
                error: '',
                notFound: false,
                isFetching: false,
                fetchingDocumentsValidate: false,
                fetchingEconomicReportValidation: false,
            });

            return updateMinimumAmounts(
                newState.set(
                    'investmentFunds',
                    newState.getIn(['steps', FIRST_STEP, 'investmentFunds'])
                )
        );
        case actionTypes.FETCH_BROKER_INVESTMENT_FUNDS_FAILURE:
            newState = state.merge({
                error: action.payload.error,
                notFound: false,
                isFetching: false
            });
            return newState.mergeIn(['steps', 1], { valid: false });
        case actionTypes.FETCH_BROKER_INVESTMENT_FUNDS_PRE_FAILURE:
            return state.merge({
                error: action.payload.error,
                notFound: false,
                isFetchingPre: false
            });
        case actionTypes.FETCH_BROKER_INVESTMENT_FUNDS_NOT_FOUND:
            return state.merge({
                error: action.payload.error,
                notFound: true,
                isFetching: false
            });
        case actionTypes.BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SKIP_CONTRIBUTION_STEP:
            partialSteps = updateStep2(state.get('steps'), action)
            visibleStep = 3;
            return state.merge({
                partialSteps,
                skipContribution: action.payload,
                visibleStep
            });
        case actionTypes.BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SET_DATE:
        case actionTypes.BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SET_PERIODICITY:
        case actionTypes.BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SET_AMOUNT:
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_SET_CUSTOM_MINIMUM_ADDITIONAL_PURCHASE_AMOUNT:
            partialSteps = updateStep2(state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_CHANGE_VALIDATE_STEP:
            visibleStep = 0;
            let skipContribution = false;
            switch (state.get('visibleStep')) {
                case 1:
                    partialSteps = updateStep1(state.get('steps'), action);
                    if (partialSteps.getIn([0, 'valid'])) {
                        visibleStep = 2
                    }else {
                        visibleStep = 1
                    }
                    break;
                case 2:
                    partialSteps = updateStep2(state.get('steps'), action);
                    visibleStep = 3;
                    break;
                case 3:
                    partialSteps = updateStep3(state.get('steps'), action);
                    visibleStep = 3;
                    skipContribution = state.get('skipContribution')
                    break;
                default:
                    partialSteps = updateStep1(state.get('steps'), action);
                    visibleStep = 1;
            }
            return state.merge({
                steps: partialSteps,
                visibleStep,
                skipContribution: skipContribution
            });
        default:
            return state;
    }
};

module.exports = documents;
