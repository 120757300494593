// @ vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');
// @ reducers
const intervenersReducer = require('./accounts/interveners');
// @ utilities
const { groupByCurrencyAndCalculateTotally, mergeOrderAndId, isFutureAccount, isBasicAccount } = require('utilities/APIParsingHelper');
const { generateAccountId } = require('utilities/APIParsingHelper');
import { getAccountsByCountries } from 'utilities/accountTypeHelper';
// @ constants
const actionTypes = require('constants/actionTypes');
const {
    API_CENTER,
    API_ENTERPRISE_NUMBER,
    CHECKING_ACCOUNT_SUBTYPE,
    CHECKING_ACCOUNT_TYPE,
    EURO_TEXT,
    FILTERS_CANCELED,
    FILTERS_VALID
} = require('constants/index');

const initialState = Immutable.fromJS({
    isFetching: false,
    balance: [{
        amount: 0,
        currency: EURO_TEXT
    }],
    balanceForGlobalPosition: [{
        amount: 0,
        currency: EURO_TEXT
    }],
    balanceDrawnForGlobalPosition: [{
        amount: 0,
        currency: EURO_TEXT
    }],
    balanceDrawn: [{
        amount: 0,
        currency: EURO_TEXT
    }],
    byId: {},
    byOrder: [],
    hasCheckingAccount: false,
    excludedAccounts: 0,
    error: '',
    blockedAccountsIds: [],
    blockedAccountsLoading: false,
    blockedAccountsSuccess: false,
    blockedAccountsError: '',
    blockedAccountsAll: false,
    blockedAccountsOrNegativeAll: false,
    isFetchingBlockedAccountBySepblac: false,
});

const howManyExcludedAccounts = (accounts) => accounts.filter(account => !account.filters.valid).length;
const hasCheckingAccount = (accounts) => accounts.some(account =>
    account.accountType === CHECKING_ACCOUNT_SUBTYPE && account.newProduct === CHECKING_ACCOUNT_TYPE
);

function checkBeneficiaries(account) {
    /*
     * This function returns true if
     * the account has more than one beneficiaries.
     * In the api response beneficiaries come in
     * 'account.criterios.c3' field with 'tipinte' prefix.
     * There are three of them. First one is for main beneficiary.
     * Second and third one can come nulled.
     **/
    let response;

    if (!!account.criterios) {
        response = account.criterios.c3.tipinte2 || account.criterios.c3.tipinte3;
    }

    return response;
}

const getProductName = (productCode, originCountry) => getAccountsByCountries(originCountry, productCode);
const getSubtypeName = (productCode, subtypeCode, originCountry) => getAccountsByCountries(originCountry, productCode + subtypeCode);

const processBalances = (balances) => {
    const currency = get(balances, 'importeSaldo.divisa', EURO_TEXT) || EURO_TEXT;
    const totalAuthorizedOverdraft = get(balances, 'importeDescubierto.importe', 0) || 0;  //totalDescubiertoAutorizado
    const totalPendingConsolidate = get(balances, 'importePendiente.importe', 0) || 0;  //totalPendienteConsolidar
    const totalPendingAuthorize = get(balances, 'importeAutorizado.importe', 0) || 0;  //totalPendienteAutorizar
    const totalRetentions = get(balances, 'importeRetenido.importe', 0) || 0;  //totalRetenciones
    const balanceInfo = {
        pregrantedCredit: {
            amount: get(balances, 'importeLimite.importe', 0) || 0,  //ultimoSaldoImpresoLimite
            currency
        },
        availableBalance: {
            amount: get(balances, 'importeDispuesto.importe', 0) || 0,  //saldoDisponible
            currency
        },
        totalPendings: {
            amount: totalRetentions + totalAuthorizedOverdraft + totalPendingConsolidate + totalPendingAuthorize,
            currency
        },
        realBalance: {
            amount: get(balances, 'importeSaldo.importe', 0) || 0, //saldoReal
            currency
        },
        totalRetentions: {
            amount: totalRetentions,
            currency
        },
        totalPendingConsolidate: {
            amount: totalPendingConsolidate,
            currency
        },
        totalPendingAuthorize: {
            amount: totalPendingAuthorize,
            currency
        }
    }

    if (__CONTACT_CENTER__) {
        balanceInfo.totalAuthorizedOverdraft = {
            amount: totalAuthorizedOverdraft,
            currency
        };

        balanceInfo.totalPendings = {
            amount: totalRetentions,
            currency
        }
    }

    return balanceInfo;
}

function buildFullContractNumber(account) {
    const contractNumber = get(account, 'cnuevo.numerodecontrato', '');
    const product = get(account, 'cnuevo.producto', '');
    if (!!contractNumber && !!product) {
        return `${API_ENTERPRISE_NUMBER}${API_CENTER}${product}${contractNumber}`;
    }
    return '';
}

function buildOldFullContractNumber(account) {
    const contractNumber = get(account, 'cviejo.numerodecontrato', '');
    const product = get(account, 'cviejo.subgrupo', '');
    if (!!contractNumber && !!product) {
        return `${API_ENTERPRISE_NUMBER}${API_CENTER}${product}${contractNumber}`;
    }
    return '';
}

function isInactiveAccount(account) {
    let isInactiveAccount = false;
    const hasNotices = get(account, 'saldosCuenta.indicadorAVISOA', '');
    const criticalNotice = get(account, 'saldosCuenta.indicadorINDACCI', '');
    if (hasNotices == 'S' && criticalNotice >= 16) {
        isInactiveAccount = true;
    }
    return isInactiveAccount;
}

function isNegativeBalance(account) {
    let isNegativeBalance = false;
    const balanceAccount = get(account, 'saldosCuenta.importeDispuesto.importe', 0);
    if (balanceAccount < 0) {
        isNegativeBalance = true;
    }
    return isNegativeBalance;
}

function updateAccountsList(state, data, originCountry, isInactiveAccountsEnabled) {
    const accounts = get(data, 'payload.datosSalidaCuentas.cuentas', []);

    let byId = {};
    let byOrder = [];
    let futureAccountsById = {};
    let futureAccountsByOrder = [];

    accounts.forEach(account => {
        const iban = {
            country: account.ibanComplex.pais,
            controlDigit: account.ibanComplex.digitodecontrol,
            codbban: account.ibanComplex.codbban
        }
        const accountId = generateAccountId(iban);
        const accountType = get(account, 'criterios.c1.subtipoproducto', '');
        const standard = get(account, 'criterios.c1.estand', '');
        const subtypeName = getSubtypeName(get(account, 'cnuevo.producto'), accountType, originCountry);

        const hasPregrantedCredit = (get(account, 'saldosCuenta.importeLimite.importe', 0) || 0) > 0

        account.balance = {
            amount: get(account, 'saldoActual.importe', 0) || 0,
            currency: get(account, 'saldoActual.divisa', EURO_TEXT) || EURO_TEXT
        };
        account.balanceDrawn = {
            amount: get(account, 'saldosCuenta.importeDispuesto.importe', 0) || 0,
            currency: get(account, 'saldosCuenta.importeDispuesto.divisa', EURO_TEXT) || EURO_TEXT
        };

        const currencyDrawnImportAccount = get(account, 'saldosCuenta.importeDispuesto.divisa', EURO_TEXT)
        const isEuroAccount = currencyDrawnImportAccount === EURO_TEXT
        const amountObjectPath = isEuroAccount
            ? 'saldosCuenta.importeDispuesto.importe'
            : 'saldoActualEuros.importe'
        const currencyObjectPath = isEuroAccount
            ? 'saldosCuenta.importeDispuesto.divisa'
            : 'saldoActualEuros.divisa'

        account.balanceDrawnForGlobalPosition = {
            amount: get(account, amountObjectPath, 0) || 0,
            currency: get(account, currencyObjectPath, EURO_TEXT)
        };
        account.balanceForGlobalPosition = {
            amount: get(account, 'saldoActualEuros.importe', 0) || 0,
            currency: get(account, 'saldoActualEuros.divisa', EURO_TEXT) || EURO_TEXT
        };

        const newProduct = get(account, 'cnuevo.producto');

        const accountObject = {
            accountId,
            accountType,
            accountConditions: account.accountConditions,
            alias: trim(account.descripcion) || subtypeName,
            aliasError: false,
            aliasTentative: '',
            balance: account.balance,
            balanceDrawn: account.balanceDrawn,
            balanceForGlobalPosition: account.balanceForGlobalPosition,
            balanceDrawnForGlobalPosition: account.balanceDrawnForGlobalPosition,
            iban: trim(account.iban),
            ibanComplex: account.ibanComplex,
            openingDate: get(account, 'saldosCuenta.fechaAperturaContrato'),
            interventionType: trim(get(account, 'descIntervenciones.nomTipInterv', '')),
            contractNumber: get(account, 'cviejo.numerodecontrato'),
            product: get(account, 'cviejo.subgrupo'),
            newContractNumber: get(account, 'cnuevo.numerodecontrato'),
            newProduct,
            standard,
            interveners: intervenersReducer(undefined, { type: null }),
            balances: processBalances(get(account, 'saldosCuenta')),
            isAliasUpdating: false,
            isFetchingBalances: false,
            hasPregrantedCredit,
            description: account.descripcion,
            filters: {
                block: get(account, 'filtros.bloque'),
                canceled: get(account, 'criterios.c2.tipsitu') === FILTERS_CANCELED,
                valid: get(account, 'filtros.valido') === FILTERS_VALID
            },
            accountHolder: trim(account.nombretitular),
            catalogData: {
                standard: get(account, 'catalogData.estandar', ''),
                product: getProductName(newProduct, originCountry),
                subtype: subtypeName
            },
            descProducto:  get(account, 'saldosCuenta.descProducto'),
            hasBeneficiaries: checkBeneficiaries(account),
            fullContractNumber: buildFullContractNumber(account),
            indicadorAVISOA: get(account, 'saldosCuenta.indicadorAVISOA'),
            indicadorINDACCI: get(account, 'saldosCuenta.indicadorINDACCI'),
            oldFullContractNumber: buildOldFullContractNumber(account),
            isInactiveAccount: isInactiveAccountsEnabled && isInactiveAccount(account),
            isNegativeBalance: isNegativeBalance(account),
            isBasicAccount: isBasicAccount(account),
        }

        if (isFutureAccount(account)) {
            futureAccountsById[accountId] = accountObject;
            futureAccountsByOrder.push(accountId);
        } else {
            byId[accountId] = accountObject;
            byOrder.push(accountId);
        }
    });

    const accountsData = mergeOrderAndId(byOrder, byId);
    const filteredInAccountsData = accountsData.filter(account => account.filters.valid);
    const nextState = {
        isFetching: false,
        balance: groupByCurrencyAndCalculateTotally(accountsData, 'balance'),
        balanceDrawn: groupByCurrencyAndCalculateTotally(accountsData, 'balanceDrawn'),
        balanceForGlobalPosition: groupByCurrencyAndCalculateTotally(filteredInAccountsData, 'balanceForGlobalPosition'),
        balanceDrawnForGlobalPosition: groupByCurrencyAndCalculateTotally(filteredInAccountsData, 'balanceDrawnForGlobalPosition'),
        hasCheckingAccount: hasCheckingAccount(accountsData),
        excludedAccounts: howManyExcludedAccounts(accountsData)
    };

    return state
        .mergeDeep(nextState)
        .set('byId', Immutable.fromJS(byId))
        .set('byOrder', Immutable.fromJS(byOrder)) // removed sorting logic from utilities/accountsSortingHelper. Thats why there is no default sorting. Hence preserving API account's order
        .set('futureAccountsById', Immutable.fromJS(futureAccountsById))
        .set('futureAccountsByOrder', Immutable.fromJS(futureAccountsByOrder));
}

function accountsReducer(state = initialState, action) {
    let accountId;
    switch (action.type) {
        case actionTypes.GLOBAL_POSITION_REQUEST_SUCCESS:
            return updateAccountsList(state, action, action.originCountry, action.isInactiveAccountsEnabled);
        case actionTypes.FETCH_ACCOUNTS_FAILURE:
            return state.merge({
                error: action.payload.error,
                isFetching: false
            });
        case actionTypes.SET_ACCOUNT_ALIAS_REQUEST:
            return state.mergeDeep({
                byId: {
                    [action.payload.accountId]: {
                        isAliasUpdating: true,
                        aliasTentative: action.payload.alias,
                        aliasError: false
                    }
                }
            });
        case actionTypes.SET_ACCOUNT_ALIAS_SUCCESS:
            return state.mergeDeep({
                byId: {
                    [action.payload.accountId]: {
                        isAliasUpdating: false,
                        alias: action.payload.alias,
                        aliasTentative: ''
                    }
                }
            });
        case actionTypes.SET_ACCOUNT_ALIAS_FAILURE:
            return state.mergeDeep({
                byId: {
                    [action.payload.accountId]: {
                        isAliasUpdating: false,
                        aliasTentative: '',
                        aliasError: true
                    }
                }
            });
        case actionTypes.FETCH_ACCOUNT_INTERVENERS_REQUEST:
        case actionTypes.FETCH_ACCOUNT_INTERVENERS_SUCCESS:
        case actionTypes.FETCH_ACCOUNT_INTERVENERS_FAILURE:
            accountId = action.payload.accountId;
            return state.setIn(['byId', accountId, 'interveners'], intervenersReducer(state.get('byId').get(accountId).get('interveners'), action));

        case actionTypes.FETCH_ACCOUNT_INTERVENERS_RESET:
            accountId = action.payload.accountId;
            return state.setIn(['byId', accountId, 'interveners'], intervenersReducer(undefined, { type: null }));

        case actionTypes.SET_PROFILE_EDIT_SETTINGS_ACCOUNT_REQUEST:
            return state.mergeDeep({
                byId: {
                    [action.payload.accountId]: {
                        filters: {
                            error: false,
                            isUpdating: true
                        }
                    }
                }
            });
        case actionTypes.SET_PROFILE_EDIT_SETTINGS_ACCOUNT_FAILURE:
            return state.mergeDeep({
                byId: {
                    [action.payload.accountId]: {
                        filters: {
                            error: true,
                            isUpdating: false
                        }
                    }
                }
            });
        case actionTypes.SET_PROFILE_EDIT_SETTINGS_ACCOUNT_SUCCESS:
            let partialMergedState = state.mergeDeep({
                byId: {
                    [action.payload.accountId]: {
                        filters: {
                            error: false,
                            isUpdating: false,
                            valid: action.payload.valid
                        }
                    }
                }
            });

            // (Andres): mergeOrderAndId does not handle immutable Lists
            const byOrder = partialMergedState.get('byOrder').toJS();
            const byId = partialMergedState.get('byId').toJS();

            const accountsData = mergeOrderAndId(byOrder, byId);
            const filteredInAccountsData = accountsData.filter(account => account.filters.valid);

            return partialMergedState.mergeDeep({
                excludedAccounts: howManyExcludedAccounts(accountsData),
                balanceForGlobalPosition: groupByCurrencyAndCalculateTotally(filteredInAccountsData, 'balanceForGlobalPosition'),
                balanceDrawnForGlobalPosition: groupByCurrencyAndCalculateTotally(filteredInAccountsData, 'balanceDrawnForGlobalPosition'),
            });
        case actionTypes.BLOCKED_ACCOUNTS_REQUEST:
            return state.merge({
                blockedAccountsLoading: true,
                blockedAccountsSuccess: false,
                blockedAccountsError: false
            });
        case actionTypes.BLOCKED_ACCOUNTS_SUCCESS:
            const immAccounts = state.get('byId');
            const accounts = action.payload.data.cuentas;
            const blockedAccounts = Immutable.List(accounts.filter(account => account.blocked));
            const negativeBalance = immAccounts.find(immAccount => accounts.find(account =>
                immAccount.get('contractNumber') === account.numeroDeContrato &&
                immAccount.get('product') === account.producto) &&
                immAccount.get('isNegativeBalance')) || Immutable.List();

            const ids = blockedAccounts.map(account => {
                const immBlockedAccounts = immAccounts.find(immAccount => (
                    immAccount.get('newContractNumber') === account.numeroDeContrato &&
                    immAccount.get('newProduct') === account.producto
                ));
                return immBlockedAccounts && immBlockedAccounts.get('accountId');
            });

            const blockedAccountsAll = blockedAccounts.size === accounts.length;
            const blockedAccountsOrNegativeAll = (blockedAccounts.size + negativeBalance.size) === accounts.length;

            return state.merge({
                blockedAccountsSuccess: true,
                blockedAccountsLoading: false,
                blockedAccountsIds: ids,
                blockedAccountsAll,
                blockedAccountsOrNegativeAll
            });
        case actionTypes.BLOCKED_ACCOUNTS_FAILURE:
            return state.merge({
                blockedAccountsSuccess: false,
                blockedAccountsLoading: false,
                blockedAccountsError: action.payload.error
            });
        case actionTypes.BLOCKED_ACCOUNTS_RESET:
            return state.merge({
                blockedAccountsIds: [],
                blockedAccountsSuccess: false,
                blockedAccountsAll: false,
                blockedAccountsOrNegativeAll: false
            });
        case actionTypes.FETCHING_BLOCKED_ACCOUNT_BY_SEPBLAC:
            return state.merge({
                isFetchingBlockedAccountBySepblac: action.payload
            });
        default:
            return state;
    }
}

module.exports = accountsReducer;
